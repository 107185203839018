interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  title?: string;
  className?: string;
  rel?: string;
  target?: string;
  children: React.ReactNode;
}

// default rel value is "noreferrer", default target value is "_blank"
export const Link = (props: LinkProps) => {
  const { to, title, className, rel, target, children, ...rest } = props;
  return (
    <a
      href={to}
      title={title}
      className={className}
      rel={rel || 'noreferrer'}
      target={target || '_blank'}
      {...rest}>
      {children}
    </a>
  );
};
