import { useState } from 'react';

import { Link } from './components/Link';

interface Social {
  name: string;
  link: string;
  icon: string;
}

function App() {
  const [residences] = useState<string[]>(['remote', 'Poland']);
  const [socials] = useState<Social[]>([
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/white.leash',
      icon: "data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26 16.6V22.4H31.2C31.6 22.4 31.8 22.8 31.8 23.2L31 27C31 27.2 30.6 27.4 30.4 27.4H26V42H20V27.6H16.6C16.2 27.6 16 27.4 16 27V23.2C16 22.8 16.2 22.6 16.6 22.6H20V16C20 12.6 22.6 10 26 10H31.4C31.8 10 32 10.2 32 10.6V15.4C32 15.8 31.8 16 31.4 16H26.6C26.2 16 26 16.2 26 16.6Z' stroke='%238742DF' stroke-width='3' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M28 42H16C6 42 2 38 2 28V16C2 6 6 2 16 2H28C38 2 42 6 42 16V28C42 38 38 42 28 42Z' stroke='%238742DF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E",
    },
    {
      name: 'LinkedIn',
      link: 'https://www.linkedin.com/in/tymoteusz-stepien/',
      icon: "data:image/svg+xml,%3Csvg width='40' height='41' viewBox='0 0 40 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34.075 34.0887H28.1588V24.805C28.1588 22.5912 28.1125 19.7462 25.065 19.7462C21.98 19.7462 21.51 22.1475 21.51 24.6412V34.0875H15.5862V14.9987H21.2762V17.6025H21.3525C22.1475 16.0987 24.0812 14.5175 26.9662 14.5175C32.9675 14.5175 34.0812 18.4688 34.0812 23.6063V34.0875L34.075 34.0887ZM8.89375 12.39C6.985 12.39 5.455 10.8463 5.455 8.945C5.455 7.05 6.99125 5.50875 8.89375 5.50875C10.7962 5.50875 12.3375 7.05 12.3375 8.945C12.3375 10.8463 10.7962 12.39 8.89375 12.39ZM11.8613 34.0887H5.925V15H11.8613V34.0887ZM37.045 0H2.95C1.32125 0 0 1.28875 0 2.88375V37.1175C0 38.7125 1.32125 40.0012 2.95 40.0012H37.0388C38.665 40.0012 40 38.7125 40 37.1175V2.88375C40 1.28875 38.665 0 37.0388 0L37.045 0Z' fill='%238742DF'/%3E%3C/svg%3E",
    },
    {
      name: 'Stackoverflow',
      link: 'https://stackoverflow.com/users/5441779/thetanadu',
      icon: "data:image/svg+xml,%3Csvg width='40' height='48' viewBox='0 0 40 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33.754 43.0543V30.4471H37.9618V47.2621H0V30.4471H4.19304V43.0543H33.754ZM8.39937 38.8539H29.5551V34.6476H8.39937V38.8539ZM8.91629 29.301L29.5565 33.6078L30.4412 29.5314L9.80984 25.2306L8.91629 29.301ZM11.5925 19.3465L30.7027 28.2627L32.4794 24.4227L13.3722 15.5005L11.594 19.3169L11.5925 19.3465ZM16.939 9.92357L33.116 23.4243L35.7937 20.2297L19.6167 6.73781L16.9538 9.91471L16.939 9.92357ZM27.3854 0L23.9471 2.54625L36.5617 19.4912L40 16.9449L27.3854 0Z' fill='%238742DF'/%3E%3C/svg%3E",
    },
    {
      name: 'dev.to',
      link: 'https://dev.to/inanoniloquent',
      icon: "data:image/svg+xml,%3Csvg width='40' height='24' viewBox='0 0 40 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.37 8.51625C12.0637 8.25 11.6012 8.13125 10.9637 8.13125H10L10.0338 12.2013L10.0988 16.2825L11.035 16.2488C11.7188 16.2488 12.0838 16.1325 12.415 15.8138C12.8188 15.415 12.8513 15.2138 12.8513 12.1475C12.8513 8.96375 12.8175 8.88125 12.37 8.51625ZM0 0V23.53H40V0H0ZM14.265 17.2662C13.535 18.23 12.5 18.5475 10.0513 18.5475H7.85125V5.9825H10.1813C12.9675 5.9825 13.7825 6.2825 14.5175 7.48125C14.9663 8.19625 14.9988 8.4825 15.0513 11.765C15.13 15.4813 15.02 16.315 14.265 17.2662ZM22.7463 8.145H18.6325V11.1013H21.1637V13.23L19.965 13.3013L18.715 13.3463V16.2963L20.7463 16.3475L22.745 16.4125V18.5475H20.0837C17.5325 18.5475 17.4137 18.5287 16.965 18.0475L16.4637 17.5775V12.3113C16.4637 7.28 16.4837 7.01125 16.88 6.5175C17.2637 5.99625 17.2962 5.99625 20.0187 5.99625H22.745V8.165L22.7463 8.145ZM30.5463 17.2338C30.2663 17.95 29.485 18.5488 28.88 18.5488C28.58 18.5488 28.1312 18.3025 27.7662 17.8988C27.2325 17.3638 27.0175 16.85 26.4 14.435L24.9012 8.78375L24.1462 5.9975H25.4163C26.08 5.9975 26.6663 6.03125 26.6663 6.0825C26.6663 6.17875 28.6 13.6475 28.77 14.1363C28.835 14.3825 29.2962 12.965 29.98 10.3013L31.08 6.1025L32.3187 6.03125C32.9825 5.9975 33.535 6.03125 33.535 6.1025C33.535 6.32875 30.7488 16.7325 30.5338 17.2338H30.5463Z' fill='%238742DF'/%3E%3C/svg%3E",
    },
    {
      name: 'Github',
      link: 'http://github.com/403-html',
      icon: "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.0013 0.00125006C8.95181 0.00125006 0 8.96056 0 20.0025C0 28.8443 5.73036 36.3385 13.6721 38.9824C14.6759 39.1712 15.0384 38.5524 15.0384 38.0174C15.0384 37.5423 15.0272 36.286 15.0197 34.6184C9.45309 35.8235 8.28052 31.9295 8.28052 31.9295C7.36921 29.6244 6.05413 29.0056 6.05413 29.0056C4.24402 27.7692 6.19789 27.7955 6.19789 27.7955C8.20301 27.9317 9.25808 29.8519 9.25808 29.8519C11.0419 32.9121 13.9384 32.027 15.0847 31.512C15.2597 30.2231 15.781 29.3368 16.3485 28.8431C11.9082 28.3418 7.2392 26.6229 7.2392 18.9587C7.2392 16.7698 8.01425 14.9872 9.29558 13.5871C9.07432 13.0858 8.39678 11.0482 9.47184 8.29302C9.47184 8.29302 11.1457 7.75923 14.9722 10.3444C16.5735 9.90062 18.2736 9.67936 19.9725 9.6681C21.6726 9.67936 23.3715 9.90187 24.9728 10.3444C28.7768 7.75923 30.4494 8.29302 30.4494 8.29302C31.5232 11.0482 30.8457 13.0846 30.6507 13.5871C31.9195 14.9872 32.6945 16.771 32.6945 18.9587C32.6945 26.6404 28.0193 28.3343 23.574 28.8231C24.269 29.4218 24.9203 30.6532 24.9203 32.522C24.9203 35.1972 24.8953 37.3523 24.8953 38.0036C24.8953 38.5237 25.2453 39.1487 26.2754 38.9474C34.2759 36.331 40 28.8306 40 20.0013C40 8.95931 31.0482 0 19.9988 0L20.0013 0.00125006Z' fill='%238742DF'/%3E%3C/svg%3E",
    },
  ]);
  const [isOpenForHiring] = useState(true);

  const residencesString = residences.join(', ').replace(/,(?!.*,)/gim, ' and');

  return (
    <div className="app">
      <header className="app__header">
        <h1 className="app__title">Senior QA Engineer</h1>
        <span className="app__text">
          {isOpenForHiring ? (
            <>
              ready to be <span className="app__accent-color">hired</span> from{' '}
              {residencesString}.{' '}
              <Link to="/tymoteusz-stepien.pdf" className="app__link">
                Download my resume
              </Link>{' '}
              or{' '}
              <Link to="mailto:tymoteusz.stepien@wp.pl" className="app__link">
                contact me
              </Link>{' '}
              if you have any questions.
            </>
          ) : (
            <>
              Currently not open for new opportunities. But feel free to contact
              me for future reference.
            </>
          )}
        </span>
        <p className="app__disclaimer">
          <span className="app__accent-color">*</span>this website may not
          reflect my approach to quality assurance :)
        </p>
        <div className="app__socials">
          {socials.map(
            (social, index) =>
              social.icon &&
              social.name && (
                <Link
                  key={index}
                  to={social.link}
                  title={social.name}
                  className="app__social-link">
                  <img
                    src={social.icon}
                    alt={social.name}
                    className="app__social-icon"
                  />
                </Link>
              ),
          )}
        </div>
      </header>
      <footer className="app__footer">
        <span className="app__text">
          page made by{' '}
          <Link
            to="
          mailto:tymoteusz.stepien@wp.pl"
            className="app__link">
            Tymoteusz Stępień
          </Link>
        </span>{' '}
        @ 2024
      </footer>
    </div>
  );
}

export default App;
